<template>
  <div class="functionalBox">
    <h2>功能特点</h2>
    <div class="characteristic" v-for="(item,index) in list" :key="index">
      <img :src="require(`@/assets/image/productCenter/`+item.icon)" alt="功能特点">
      <h3>{{item.title}}</h3>
      <p>{{item.intro}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'functionalItem',
  props: ['list']
}
</script>

<style lang="scss" scoped>
.functionalBox {
  padding: 0.9rem 0.64rem 0.5rem;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    font-weight: bold;
    color: #303b50;
    line-height: 0.67rem;
    margin-bottom: 0.64rem;
    text-align: center;
  }
}
.characteristic {
  width: 6.22rem;
  min-height: 3.15rem;
  background: #f9fbff;
  box-shadow: 0px 0.04rem 0.16rem 0px rgba(31, 37, 81, 0.11);
  border-radius: 0.02rem;
  padding: 0.24rem 0.32rem;
  box-sizing: border-box;
  margin-bottom: 0.4rem;
  img {
    width: 0.8rem;
  }
  h3 {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.4rem;
    margin: 0.24rem 0;
  }
  p {
    font-size: 0.26rem;
    color: #303b50;
    line-height: 0.33rem;
  }
}
</style>