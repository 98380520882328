<template>
  <div>
    <div class="timestampBox">
      <img src="@/assets/image/productCenter/timestampBanner.png" alt="可信时间戳">
      <h2>可信时间戳</h2>
      <p>可信时间戳服务的本质是将用户的电子数据的Hash值和权威时间源绑定，在此基础上通过时间戳服务中心数字签名，产生不可伪造的时间戳文件。通过电子数据及对应可信时间戳文件有效证明电子数据的完整性及产生时间。</p>
    </div>
    <v-functional :list="functional" />
    <v-featuresItem :list="features" />
    <v-learnMore />
    <v-networkSecurity />
  </div>
</template>

<script>
import learnMore from './components/learnMore.vue';
import featuresItem from './components/featuresItem.vue';
import functionalItem from './components/functionalItem.vue';
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      functional: [
        {
          icon: 'timestamp1.png',
          title: '签发时间戳',
          intro:
            '用户将需要加盖时间戳的数据，经过消息摘要生成时间戳请求后发送至时间戳服务器，由时间戳服务器返回时间戳响应消息给客户端完成时间戳的签发。'
        },
        {
          icon: 'timestamp2.png',
          title: '同步可信时间',
          intro:
            '能够与第三方授时中心、卫星授权时间源进行时间同步，确保签发时间戳时所获取的时间的有效性。'
        },
        {
          icon: 'timestamp3.png',
          title: '验证时间戳',
          intro:
            '用户将时间戳服务器签发的时间戳进行验证。验证内容包括：使用时间戳证书验证用户给定的时间戳是否由该时间戳服务器签发；用户提供时间戳和源文件，验证该时间戳是该文件的时间戳。'
        },
        {
          icon: 'timestamp4.png',
          title: '密钥管理',
          intro:
            '时间戳服务器中采用了硬件密码设备、密钥存储访问控制、密钥管理安全审计等多种措施对密钥进行安全防护。'
        },
        {
          icon: 'timestamp5.png',
          title: '硬件加密',
          intro:
            '支持经过国家密码管理局鉴定的密码卡设备，可进行时间戳密钥的生成和管理。'
        },
        {
          icon: 'orgCert-icon3.png',
          title: '法律效力',
          intro:
            '采用权威的第三方认证机构颁发的时间戳服务器证书，时间戳符合《中华人民共和国电子签名法》。'
        }
      ],
      features: [
        {
          title: '免部署',
          intro: '免部署即享可信时间戳云服务，安全可信，节约成本。'
        },
        {
          title: '权威国家时间源',
          intro:
            '豸信CA建立的可信时间戳云服务平台依托国家授时中心的可信时间源，合法合规，有法律保障。'
        }
      ]
    }
  },
  components: {
    'v-learnMore': learnMore,
    'v-featuresItem': featuresItem,
    'v-functional': functionalItem,
    'v-networkSecurity': networkSecurity,
  },
}
</script>

<style lang="scss" scoped>
.timestampBox {
  width: 7.5rem;
  height: 9.8rem;
  background: #f0f4ff;
  padding: 0.65rem 0.53rem 1.12rem;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 5.29rem;
  }
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin: 0.64rem 0 0.26rem;
  }
  p {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.44rem;
  }
}
</style>