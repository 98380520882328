<template>
  <div class="productFeatures">
    <h2>产品特性</h2>
    <div class="features" v-for="(item,index) in list" :key="index">
      <h3>{{item.title}}</h3>
      <p>{{item.intro}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'featuresItem',
  props: ['list']
}
</script>

<style lang="scss" scoped>
.productFeatures {
  padding: 0 0.64rem 0.5rem;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    font-weight: bold;
    color: #303b50;
    line-height: 0.67rem;
    margin-bottom: 0.64rem;
    text-align: center;
  }
}
.features {
  width: 6.22rem;
  min-height: 2.12rem;
  background: #ffffff;
  box-shadow: 0px 0.04rem 0.16rem 0px rgba(31, 37, 81, 0.11);
  border-radius: 0.02rem;
  border: 1px solid #ffffff;
  padding: 0.24rem 0.32rem;
  box-sizing: border-box;
  margin-bottom: 0.4rem;
  h3 {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.4rem;
    margin-bottom: 0.16rem;
  }
  p {
    font-size: 0.26rem;
    color: #303b50;
    line-height: 0.36rem;
  }
}
</style>